import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Accordion } from '@appcues/sonar';
import { FieldSet, Label } from 'ext/components/ui';
import { EditorShape } from 'entities/step-groups';
import { PAGING_DOTS, TRAIT_LABELS } from 'lib/trait';
import { THEMES } from 'lib/user-preferences';
import ColorInput from 'components/ColorInput';
import {
  Content,
  Controls,
  AlignmentSpacing,
  useStyleSettings,
} from 'components/SideBarSettings/Shared';

export function PagingDots({ editor, onChange, theme = 'light' }) {
  const { pagingDots } = editor;
  const { style } = pagingDots || {};

  const handleChange = useCallback(
    patch => {
      onChange({ pagingDots: { style: patch.style || patch } });
    },
    [onChange]
  );

  const [valueFor, handleChangeFor] = useStyleSettings({
    onChange: handleChange,
    style,
    theme,
  });

  return (
    <Content>
      <Controls>
        <FieldSet>
          <Label htmlFor="step-indicator-active-color">Active</Label>
          <ColorInput
            id="step-indicator-active-color"
            color={valueFor.foregroundColor}
            onChange={handleChangeFor.foregroundColor}
          />
        </FieldSet>
        <FieldSet>
          <Label htmlFor="step-indicator-inactive-color">Inactive</Label>
          <ColorInput
            id="step-indicator-inactive-color"
            color={valueFor.backgroundColor}
            onChange={handleChangeFor.backgroundColor}
          />
        </FieldSet>
      </Controls>

      <Accordion.Root
        collapsible="true"
        defaultValue={['alignment-spacing']}
        type="multiple"
      >
        <AlignmentSpacing
          style={style}
          spacingLabel={TRAIT_LABELS[PAGING_DOTS]}
          onChange={handleChange}
          hasMargin
        />
      </Accordion.Root>
    </Content>
  );
}

PagingDots.propTypes = {
  editor: EditorShape,
  onChange: PropTypes.func,
  theme: PropTypes.oneOf(THEMES),
};

export default PagingDots;
