import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { selectStepGroup, EditorShape } from 'entities/step-groups';
import { selectStepChild, ContentShape } from 'entities/step-children';
import { selectSelected } from 'entities/selected';
import { Shape as ExperienceShape } from 'entities/experiences';
import { focus, Shape as BlockShape } from 'entities/block';
import { selectActiveLocale } from 'entities/locales';
import { TRAITS, LAYOUT_TRAITS, TRAIT_LABELS } from 'lib/trait';
import { localizeContent } from 'lib/localization';
import { selectBlockContent } from 'lib/selectors';
import { getParentBlock } from 'lib/block';
import { MODES } from 'hooks/use-mode';
import LightTheme from 'components/LightTheme';
import {
  BlockSettings,
  CustomComponentsSettings,
  PresentationSettings,
  TraitSettings,
} from 'components/SideBarSettings';
import {
  AddStepShape,
  InsertComponentPositionShape,
  selectAddStepOfType,
  selectInsertComponentPosition,
} from 'entities/user-interface';
import { PRESENTATIONS } from 'lib/presentation';
import { getBlockSettings } from './get-block-settings';
import SideBar from './SideBar';

const SIDEBAR = {
  newStep: 'newStep',
  editTrait: 'editTrait',
  editBlock: 'editBlock',
  selectComponent: 'selectComponent',
  empty: 'empty',
};

export function SideBarManager({
  experience,
  isSpoofing,
  mode,
  selectedStepTypeToAdd,
  selectedInsertComponentPosition,
  selectedStepGroup,
  selectedStepChild,
  selectedTrait,
  selectedBlockContent,
  editor,
  layoutTrait,
  content,
  localeId,
  onClick,
}) {
  const sidebar = useMemo(() => {
    if (mode === MODES.new || selectedStepTypeToAdd.layoutTrait !== undefined) {
      return SIDEBAR.newStep;
    }
    if (mode !== MODES.edit) {
      return SIDEBAR.empty;
    }
    if (selectedInsertComponentPosition.rowId !== undefined) {
      return SIDEBAR.selectComponent;
    }
    return selectedBlockContent !== null
      ? SIDEBAR.editBlock
      : SIDEBAR.editTrait;
  }, [
    mode,
    selectedStepTypeToAdd,
    selectedInsertComponentPosition,
    selectedBlockContent,
  ]);

  if (sidebar === SIDEBAR.empty) return null;

  const localizedContent = localizeContent(content, localeId);
  const localizedSelectedBlock = localizeContent(
    selectedBlockContent,
    localeId
  );

  const { blockLabel = 'Block', ...blockSettings } = selectedBlockContent
    ? getBlockSettings(localizedContent, localizedSelectedBlock)
    : {};

  const { blockLabel: _, ...blockSettingsWithVariations } = selectedBlockContent
    ? getBlockSettings(content, selectedBlockContent)
    : {};

  const { id: selectedBlock } = selectedBlockContent ?? {};
  const { id: parentId } = getParentBlock(content, selectedBlock) ?? {};
  const blockSelection = {
    selected: blockLabel,
    onClick: selectedBlock ? () => onClick(parentId || null) : null,
  };

  const isPublished = experience?.published;
  const bannersShown = [isSpoofing, isPublished].filter(Boolean).length;

  const handleTitle = () => {
    const titles = {
      [SIDEBAR.newStep]: selectedStepTypeToAdd.presentation
        ? `Add ${
            PRESENTATIONS[selectedStepTypeToAdd.layoutTrait][
              selectedStepTypeToAdd.presentation
            ]
          } step`
        : 'Add',
      [SIDEBAR.editBlock]: blockLabel,
      [SIDEBAR.editTrait]: TRAIT_LABELS[selectedTrait || layoutTrait],
      [SIDEBAR.selectComponent]: 'Add custom component',
    };

    return titles[sidebar] ?? 'Add';
  };

  return (
    <LightTheme>
      <SideBar
        title={handleTitle()}
        bannersShown={bannersShown}
        blockSelection={blockSelection}
      >
        {sidebar === SIDEBAR.newStep && (
          <PresentationSettings
            experience={{
              id: experience?.id,
              type: experience?.type,
              platform: experience?.platform,
            }}
            selectedStepTypeToAdd={selectedStepTypeToAdd}
          />
        )}

        {sidebar === SIDEBAR.editTrait && (
          <TraitSettings
            selectedStepGroup={selectedStepGroup}
            selectedStepChild={selectedStepChild}
            selectedTrait={selectedTrait}
            editor={editor}
            layoutTrait={layoutTrait}
          />
        )}

        {sidebar === SIDEBAR.editBlock && (
          <BlockSettings
            selectedStepChild={selectedStepChild}
            blockSettings={blockSettings}
            blockSettingsWithVariations={blockSettingsWithVariations}
            content={content}
          />
        )}

        {sidebar === SIDEBAR.selectComponent && <CustomComponentsSettings />}
      </SideBar>
    </LightTheme>
  );
}

SideBarManager.propTypes = {
  experience: ExperienceShape,
  isSpoofing: PropTypes.bool,
  mode: PropTypes.oneOf(Object.values(MODES)),
  selectedStepTypeToAdd: AddStepShape,
  selectedInsertComponentPosition: InsertComponentPositionShape,
  selectedStepGroup: PropTypes.string,
  selectedStepChild: PropTypes.string,
  selectedTrait: PropTypes.oneOf(TRAITS),
  selectedBlockContent: BlockShape,
  editor: EditorShape,
  layoutTrait: PropTypes.oneOf(LAYOUT_TRAITS),
  content: ContentShape,
  localeId: PropTypes.string,
  onClick: PropTypes.func,
};

const mapStateToProps = state => {
  const {
    stepGroup: selectedStepGroup,
    stepChild: selectedStepChild,
    trait: selectedTrait,
  } = selectSelected(state) ?? {};
  const { editor } = selectStepGroup(state, selectedStepGroup) ?? {};
  const { type: layoutTrait } = editor ?? {};
  const { content } = selectStepChild(state, selectedStepChild) ?? {};
  const selectedBlockContent = selectBlockContent(state);
  const { id: localeId } = selectActiveLocale(state) ?? {};
  const selectedStepTypeToAdd = selectAddStepOfType(state);
  const selectedInsertComponentPosition = selectInsertComponentPosition(state);

  return {
    selectedStepTypeToAdd,
    selectedInsertComponentPosition,
    selectedStepGroup,
    selectedStepChild,
    selectedTrait,
    selectedBlockContent,
    editor,
    layoutTrait,
    content,
    localeId,
  };
};

const mapDispatchToProps = {
  onClick: focus,
};

export default connect(mapStateToProps, mapDispatchToProps)(SideBarManager);
