import React, { useState } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import { Icon, Input, Label } from '@appcues/sonar';
import { faMobileAlt } from '@fortawesome/free-solid-svg-icons/faMobileAlt';
import { faSun } from '@fortawesome/free-solid-svg-icons/faSun';
import { faMoon } from '@fortawesome/free-solid-svg-icons/faMoon';
import { faParagraphRtl } from '@fortawesome/pro-solid-svg-icons/faParagraphRtl';
import { Select, Tooltip } from 'ext/components/ui';
import { Shape as ScreenShape } from 'entities/screens';
import { LocaleShape } from 'entities/user-preferences';
import { isEmbedExperience, EXPERIENCE_TYPES } from 'lib/experiences';
import { PLATFORMS } from 'lib/platform';
import { USER_PREFERENCES } from 'lib/user-preferences';
import { getDevice, getDeviceOptions } from 'lib/device';
import { HelpLabel } from 'components/SideBarSettings/Shared';
import Divider from 'components/Divider';
import LocalesDropdown from 'components/LocalesDropdown';
import {
  Wrapper,
  Menu,
  Group,
  Button,
  ScreenResolution,
  EmbedWidthSize,
} from './styled';

const [THEME, DIRECTION, ORIENTATION, VIEWPORT, EMBED, LOCALE] =
  Object.keys(USER_PREFERENCES);
const [LIGHT, DARK] = USER_PREFERENCES[THEME];
const [LTR, RTL] = USER_PREFERENCES[DIRECTION];
const [PORTRAIT, LANDSCAPE] = USER_PREFERENCES[ORIENTATION];
const { min, max } = USER_PREFERENCES[EMBED];

export const TopMenu = ({
  screen,
  theme = LIGHT,
  direction = LTR,
  orientation = PORTRAIT,
  viewport,
  platform,
  embed,
  locale = LOCALE,
  experienceType,
  onPreferenceChange,
  hasLocalizationAccess,
}) => {
  const [visibleTooltip, setVisibleTooltip] = useState('');

  const devicePlatformOptions = getDeviceOptions(platform);
  const deviceViewport = getDevice(platform, viewport);

  const onLocaleChange = (value, dir) => {
    onPreferenceChange(LOCALE, value);
    if (dir) {
      onPreferenceChange(DIRECTION, dir);
    }
  };

  const handleButton = ({
    label,
    isActive,
    isDisabled,
    onClick,
    icon,
    isRotated = false,
  }) => (
    <Tooltip
      label={label}
      onMouseOut={() => setVisibleTooltip('')}
      onMouseOver={() => setVisibleTooltip(label)}
      visible={visibleTooltip === label}
      placement="bottom"
    >
      <Button
        aria-label={label}
        isActive={isActive}
        onClick={onClick}
        isRotated={isRotated}
        isDisabled={isDisabled}
      >
        <Icon icon={icon} />
      </Button>
    </Tooltip>
  );

  const defaultSelectionOptions = (
    <>
      <Group>
        {handleButton({
          label: 'Portrait',
          isActive: orientation === PORTRAIT,
          onClick: () => onPreferenceChange(ORIENTATION, PORTRAIT),
          icon: faMobileAlt,
        })}

        {handleButton({
          label: 'Landscape',
          isActive: orientation === LANDSCAPE,
          onClick: () => onPreferenceChange(ORIENTATION, LANDSCAPE),
          icon: faMobileAlt,
          isRotated: true,
        })}
      </Group>

      <Divider />

      <Select
        options={devicePlatformOptions}
        value={deviceViewport}
        onChange={({ value }) => onPreferenceChange(VIEWPORT, value)}
      />
    </>
  );

  const screenResolution = (
    <ScreenResolution>
      {screen?.metadata?.deviceWidth} x {screen?.metadata?.deviceHeight}
      <HelpLabel placement="bottom">
        Device preview is locked while app <br />
        screens are in use.
      </HelpLabel>
    </ScreenResolution>
  );

  const handleWidthSize = debounce(({ target }) => {
    const { valueAsNumber } = target;
    if (valueAsNumber < Number(min) || valueAsNumber > Number(max)) {
      return;
    }

    onPreferenceChange(EMBED, target.value);
  }, 500);

  const embedWidthSize = (
    <EmbedWidthSize>
      <Label>Width</Label>
      <Input
        id="embed-width-size"
        defaultValue={embed}
        onChange={handleWidthSize}
        type="number"
        max={max}
        min={min}
      />
    </EmbedWidthSize>
  );

  const handleSelectionOptions = () => {
    if (screen) return screenResolution;
    if (isEmbedExperience(experienceType)) return embedWidthSize;
    return defaultSelectionOptions;
  };

  return (
    <Wrapper data-theme="light">
      <Menu>
        <Group>
          {handleButton({
            label: 'Light mode',
            isActive: theme === LIGHT,
            onClick: () => onPreferenceChange(THEME, LIGHT),
            icon: faSun,
          })}
          {handleButton({
            label: 'Dark mode',
            isActive: theme === DARK,
            onClick: () => onPreferenceChange(THEME, DARK),
            icon: faMoon,
          })}
        </Group>

        <Divider />

        {handleSelectionOptions()}

        <Divider />

        <Group>
          {direction === LTR &&
            handleButton({
              label: 'Enable RTL text editing',
              isActive: false,
              onClick: () => onPreferenceChange(DIRECTION, RTL),
              icon: faParagraphRtl,
            })}
          {direction === RTL &&
            handleButton({
              label: 'Disable RTL text editing',
              isActive: true,
              onClick: () => onPreferenceChange(DIRECTION, LTR),
              icon: faParagraphRtl,
            })}
        </Group>

        {hasLocalizationAccess && (
          <>
            <Divider />

            <LocalesDropdown
              selectedLocale={locale}
              onLocaleChange={onLocaleChange}
            />
          </>
        )}
      </Menu>
    </Wrapper>
  );
};

TopMenu.propTypes = {
  theme: PropTypes.oneOf(USER_PREFERENCES[THEME]),
  direction: PropTypes.oneOf(USER_PREFERENCES[DIRECTION]),
  orientation: PropTypes.oneOf(USER_PREFERENCES[ORIENTATION]),
  screen: ScreenShape,
  viewport: PropTypes.string,
  platform: PropTypes.oneOf(PLATFORMS),
  embed: PropTypes.string,
  locale: LocaleShape,
  experienceType: PropTypes.oneOf(EXPERIENCE_TYPES),
  onPreferenceChange: PropTypes.func,
  hasLocalizationAccess: PropTypes.bool,
};

export default TopMenu;
