import { css } from 'styled-components';

const outlineStyle = (lineStyle = 'solid') =>
  `2px ${lineStyle} var(--color-blue-500)`;

const outlineInteractions = ({ selected, hovered }) => css`
  outline: ${selected ? outlineStyle() : 'none'};

  &:hover {
    outline: ${hovered ? outlineStyle('dashed') : 'none'};
  }
`;

const borderStyle = (lineStyle = 'solid') => css`
  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 24px;
    border: 2px ${lineStyle} var(--color-blue-500);
  }
`;

const borderInteractions = ({ selected, hovered }) => css`
  button {
    padding-left: 7px;
    display: none;
  }

  ${selected &&
  css`
    ${borderStyle()}

    && button {
      display: flex;
    }
  `}

  &:hover {
    ${hovered && borderStyle('dashed')};
  }
`;

const interactionStyles = ({
  unreach,
  selected,
  hovered,
  outline = true,
}) => css`
  pointer-events: ${unreach ? 'none' : 'auto'};

  ${outline
    ? outlineInteractions({ selected, hovered })
    : borderInteractions({ selected, hovered })};
`;

export default interactionStyles;
