import React from 'react';
import PropTypes from 'prop-types';
import { Accordion } from '@appcues/sonar';
import { Label, Select } from 'ext/components/ui';
import { BlockContentShape } from 'entities/block';
import { SINGLE_SELECT, MULTI_SELECT } from 'lib/block';
import {
  Controls,
  GroupedFieldSet,
  GroupedField,
  useAccordionClick,
} from 'components/SideBarSettings/Shared';
import { RequiredField } from 'components/SideBarSettings/Survey';

export default function SelectBehavior({
  blockType,
  options = [],
  minSelections,
  maxSelections,
  required,
  onChange,
  onRequiredChange,
  onErrorLabelChange,
}) {
  const trackAccordion = useAccordionClick();

  const selectionOptions = Array.from({ length: options.length + 1 }).map(
    (_, index) => ({
      label: `${index}`,
      value: index,
    })
  );

  const nonMinimumOption = [selectionOptions.shift()];

  return (
    <Accordion.Item value="behavior">
      <Accordion.Header>
        <Accordion.Trigger onClick={() => trackAccordion('Select', 'Behavior')}>
          Behavior
        </Accordion.Trigger>
      </Accordion.Header>
      <Accordion.Content>
        <Controls>
          {blockType === MULTI_SELECT && (
            <GroupedFieldSet>
              <GroupedField half>
                <Label htmlFor="min-selections" isDisabled={!required}>
                  Min selections
                </Label>
                <Select
                  id="min-selections"
                  aria-label="Min selections"
                  options={
                    required
                      ? selectionOptions.filter(({ value }) =>
                          maxSelections ? value <= maxSelections : true
                        )
                      : nonMinimumOption
                  }
                  isDisabled={!required}
                  value={
                    required
                      ? selectionOptions.filter(
                          ({ value }) => value === minSelections
                        )
                      : nonMinimumOption
                  }
                  onChange={({ value }) => onChange({ minSelections: value })}
                  portal
                />
              </GroupedField>
              <GroupedField half>
                <Label htmlFor="max-selections">Max selections</Label>
                <Select
                  id="max-selections"
                  aria-label="Max selections"
                  options={selectionOptions.filter(({ value }) =>
                    minSelections ? value >= minSelections : true
                  )}
                  placeholder="None"
                  value={selectionOptions.filter(
                    ({ value }) => value === maxSelections
                  )}
                  onChange={({ value }) => onChange({ maxSelections: value })}
                  portal
                />
              </GroupedField>
            </GroupedFieldSet>
          )}
          <RequiredField
            onRequiredChange={onRequiredChange}
            onErrorLabelChange={onErrorLabelChange}
          />
        </Controls>
      </Accordion.Content>
    </Accordion.Item>
  );
}

SelectBehavior.propTypes = {
  blockType: PropTypes.oneOf([SINGLE_SELECT, MULTI_SELECT]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      content: BlockContentShape,
      value: PropTypes.string,
    })
  ),
  minSelections: PropTypes.number,
  maxSelections: PropTypes.number,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  onRequiredChange: PropTypes.func,
  onErrorLabelChange: PropTypes.func,
};
