import { createCollectionReducer } from 'ext/lib/collections';
import { TYPE } from './actions';

export default createCollectionReducer(TYPE);

export const selectEntitlementsByName = (state, name) => {
  return state?.entitlements[name] ?? null;
};

/**
 * Checks if a specific entitlement is enabled based on the provided configuration.
 *
 * @param {Object} state - The current application state, which contains entitlement information.
 * @param {string} name - The name of the entitlement to check.
 * @param {Object} config - The configuration object used to determine the type of entitlement. Not required in case it is not boolean type
 * @param {string} config.type - The type of the entitlement when 'boolean'.
 *
 * @returns {boolean} - Returns `true` if the entitlement is enabled, otherwise `false`.
 *
 * @example
 * // For a boolean entitlement type
 * const isEnabled = isEntitlementEnabled(state, 'entitlement_name', { type: 'boolean' });
 * console.log(isEnabled); // true or false based on allowed_units
 *
 * @example
 * // For a non-boolean entitlement type
 * const isEnabled = isEntitlementEnabled(state, 'another_entitlement_name');
 * console.log(isEnabled); // true if used_units < allowed_units, otherwise false
 */
export const isEntitlementEnabled = (state, name, config) => {
  const entitlement = selectEntitlementsByName(state, name);

  if (!entitlement) return false;

  return config?.type === 'boolean'
    ? entitlement?.allowed_units > 0
    : entitlement?.used_units < entitlement?.allowed_units;
};
