import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import isEqual from 'lodash.isequal';
import { Accordion } from '@appcues/sonar';
import { FontIcon, FieldSet, Label, LightButton } from 'ext/components/ui';
import { ColorShape } from 'entities/step-children';
import { selectUserPreferences } from 'entities/user-preferences';
import { selectPreviewType, updatePreview } from 'entities/user-interface';
import { THEMES } from 'lib/user-preferences';
import { SELECTED_STATE } from 'lib/user-interface';
import { SINGLE_SELECT, MULTI_SELECT } from 'lib/block';
import ColorInput from 'components/ColorInput';
import {
  DEFAULT_COLORS,
  Controls,
  GroupedFieldSet,
  GroupedField,
  HelpLabel,
  useStyleSettings,
  useAccordionClick,
} from 'components/SideBarSettings/Shared';

const selectElement = {
  [SINGLE_SELECT]: 'radio button',
  [MULTI_SELECT]: 'checkbox',
};

export function SelectStyle({
  id: blockId,
  blockType,
  selectedColor,
  unselectedColor,
  onChange,
  selectedPreviewType,
  theme,
  onPreviewUpdate,
}) {
  const trackAccordion = useAccordionClick();

  const selectedStateColor = selectedPreviewType(blockId, SELECTED_STATE);

  const handleChange = useCallback(
    patch => {
      const behaviorUpdated = isEqual(selectedColor, patch.selectedColor)
        ? 'unselectedColor'
        : 'selectedColor';

      onChange({
        [behaviorUpdated]: patch[behaviorUpdated],
      });
    },
    [selectedColor, onChange]
  );

  const [valueFor, handleChangeFor] = useStyleSettings({
    onChange: handleChange,
    style: { selectedColor, unselectedColor },
    theme,
  });

  return (
    <Accordion.Item value="style">
      <Accordion.Header>
        <Accordion.Trigger onClick={() => trackAccordion('Select', 'Style')}>
          Style
        </Accordion.Trigger>
      </Accordion.Header>
      <Accordion.Content>
        <Controls>
          <FieldSet>
            <Label htmlFor="selector-color">Selector color</Label>
            <ColorInput
              id="selector-color"
              color={valueFor.unselectedColor}
              placeholder={DEFAULT_COLORS.unselectedColor}
              onChange={handleChangeFor.unselectedColor}
            />
          </FieldSet>
          <GroupedFieldSet>
            <GroupedField>
              <Label htmlFor="selected-color">
                Selected state color
                <HelpLabel>
                  Option to set the color for the <br />
                  selected state of the {selectElement[blockType]}
                </HelpLabel>
              </Label>
              <ColorInput
                id="selected-color"
                color={valueFor.selectedColor}
                onChange={handleChangeFor.selectedColor}
              />
            </GroupedField>

            <GroupedField>
              <LightButton
                aria-label="Preview error color"
                aria-pressed={!!selectedStateColor.show}
                kind="tertiary"
                onClick={() =>
                  onPreviewUpdate({
                    blockId,
                    previewType: selectedStateColor.type ?? SELECTED_STATE,
                    show: !(selectedStateColor.show ?? false),
                  })
                }
              >
                <FontIcon icon="eye" />
              </LightButton>
            </GroupedField>
          </GroupedFieldSet>
        </Controls>
      </Accordion.Content>
    </Accordion.Item>
  );
}

SelectStyle.propTypes = {
  id: PropTypes.string,
  blockType: PropTypes.oneOf([SINGLE_SELECT, MULTI_SELECT]),
  selectedColor: ColorShape,
  unselectedColor: ColorShape,
  onChange: PropTypes.func,
  selectedPreviewType: PropTypes.func,
  theme: PropTypes.oneOf(THEMES),
  onPreviewUpdate: PropTypes.func,
};

const mapStateToProps = state => ({
  selectedPreviewType: (blockId, previewType) =>
    selectPreviewType(state, { blockId, previewType }),
  theme: selectUserPreferences(state).theme,
});

const mapDispatchToProps = {
  onPreviewUpdate: updatePreview,
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectStyle);
