import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Accordion, Text } from '@appcues/sonar';
import { FieldSet, Label, Select } from 'ext/components/ui';
import {
  selectUserProperties,
  userPropertiesShape,
} from 'ext/entities/user-properties';
import {
  getContinue,
  getClose,
  getNoOp,
  Shape as ActionsShape,
} from 'lib/actions';
import { selectIsExperienceFirstStep } from 'lib/selectors';
import { TARGET_INTERACTION } from 'lib/trait';
import {
  BUTTON,
  CUSTOM_COMPONENT,
  EMOJI,
  HERO,
  IMAGE,
  IMAGE_WITH_TEXT,
} from 'lib/block';
import { Controls } from 'components/SideBarSettings/Shared/styled';
import useAccordionClick from 'components/SideBarSettings/Shared/use-accordion-click';
import {
  GoToUrlOption,
  TriggerFlowOption,
  DismissFlowOption,
  CustomStepOption,
  TrackEventOption,
  UserPropertiesOption,
  SubmitFormOption,
  RequestReviewOption,
  RequestPushOption,
} from './options';
import {
  ACTION_TYPES,
  ACTION_TYPES_LIST,
  getActionTypeConfig,
} from './helpers';

const getDescription = block =>
  `When users interact with the ${block}, Appcues can advance, dismiss the Flow, redirect users, or launch content.`;

const contextHandler = {
  [BUTTON]: {
    label: 'Button',
    filterActions: [ACTION_TYPES.NO_OP],
  },
  [IMAGE]: {
    label: 'Image tap',
    description: getDescription('image'),
  },
  [HERO]: {
    label: 'Hero tap',
    description: getDescription('hero section'),
  },
  [IMAGE_WITH_TEXT]: {
    label: 'Icon with text tap',
    description: getDescription('icon with text'),
  },
  [EMOJI]: {
    label: 'Emoji tap',
    description: getDescription('emoji'),
  },
  [TARGET_INTERACTION]: {
    label: 'Target interaction',
    description: getDescription('target'),
  },
  [CUSTOM_COMPONENT]: {
    label: 'Custom component',
    description: `The custom component can trigger these actions.`,
  },
};

export const actionContexts = Object.keys(contextHandler);

function Actions({
  actions,
  context,
  hasSurveyBlock,
  userProperties,
  isExperienceFirstStep,
  onActionsChange,
  children,
}) {
  const trackAccordion = useAccordionClick();

  const {
    label,
    description,
    filterActions = [],
  } = contextHandler[context] || {};

  const [actionConfig, setActionConfig] = useState(
    getActionTypeConfig(actions) || {}
  );

  const [actionType, setActionType] = useState(
    actionConfig.type || ACTION_TYPES.NO_OP
  );

  useEffect(() => {
    if (actions) {
      const actionTypeConfig = getActionTypeConfig(actions);
      setActionType(actionTypeConfig.type || ACTION_TYPES.NO_OP);
      setActionConfig(actionTypeConfig);
    }
  }, [actions]);

  const actionOptions = useMemo(
    () =>
      ACTION_TYPES_LIST.reduce(
        (acc, action) =>
          filterActions.includes(action.value)
            ? acc
            : [
                ...acc,
                {
                  label: action.label,
                  value: action.value,
                },
              ],
        []
      ),
    [filterActions]
  );

  const handleActionType = ({ value }) => {
    setActionType(value);
    setActionConfig({});

    let newAction;

    if (value === ACTION_TYPES.NEXT_STEP) {
      newAction = getContinue();
    }

    if (value === ACTION_TYPES.PREVIOUS_STEP) {
      newAction = getContinue({ offset: -1 });
    }

    if (value === ACTION_TYPES.DISMISS_FLOW) {
      newAction = getClose({ markComplete: true });
    }

    if (value === ACTION_TYPES.NO_OP) {
      newAction = getNoOp();
    }

    if (newAction) {
      onActionsChange({ actions: [newAction] });
    }
  };

  return (
    <Accordion.Item value="actions">
      <Accordion.Header>
        <Accordion.Trigger onClick={() => trackAccordion(label, 'Actions')}>
          Actions
        </Accordion.Trigger>
      </Accordion.Header>
      <Accordion.Content>
        <Controls>
          <FieldSet>
            {description && <Text>{description}</Text>}
            <Label htmlFor={`${label}-action`}>{`${label} action`}</Label>
            <Select
              id={`${label}-action`}
              value={actionOptions.filter(({ value }) => actionType === value)}
              options={actionOptions.filter(
                ({ value }) =>
                  !isExperienceFirstStep || value !== ACTION_TYPES.PREVIOUS_STEP
              )}
              onChange={handleActionType}
              placeholder="Select"
              portal
            />
          </FieldSet>

          {actionType === ACTION_TYPES.URL && (
            <GoToUrlOption
              config={actionConfig?.config}
              onChange={onActionsChange}
            />
          )}

          {actionType === ACTION_TYPES.TRIGGER_FLOW && (
            <TriggerFlowOption
              config={actionConfig?.config}
              onChange={onActionsChange}
            />
          )}

          {actionType === ACTION_TYPES.DISMISS_FLOW && (
            <DismissFlowOption
              actions={actions}
              config={actionConfig?.config}
              onChange={onActionsChange}
            />
          )}

          {actionType === ACTION_TYPES.CUSTOM_STEP && (
            <CustomStepOption
              config={actionConfig?.config}
              onChange={onActionsChange}
            />
          )}

          <TrackEventOption
            config={actionConfig?.config}
            onChange={onActionsChange}
          />

          <UserPropertiesOption
            userPropertiesList={userProperties}
            config={actionConfig?.config}
            onChange={onActionsChange}
          />

          {hasSurveyBlock && (
            <SubmitFormOption actions={actions} onChange={onActionsChange} />
          )}

          {actionType === ACTION_TYPES.DISMISS_FLOW && (
            <>
              <RequestReviewOption
                actions={actions}
                onChange={onActionsChange}
              />
              <RequestPushOption actions={actions} onChange={onActionsChange} />
            </>
          )}
          {children}
        </Controls>
      </Accordion.Content>
    </Accordion.Item>
  );
}

Actions.propTypes = {
  actions: PropTypes.arrayOf(ActionsShape),
  context: PropTypes.oneOf(actionContexts),
  hasSurveyBlock: PropTypes.bool,
  userProperties: userPropertiesShape,
  isExperienceFirstStep: PropTypes.bool,
  onActionsChange: PropTypes.func,
  children: PropTypes.node,
};

const mapStateToProps = state => ({
  isExperienceFirstStep: selectIsExperienceFirstStep(state),
  userProperties: selectUserProperties(state),
});

export default connect(mapStateToProps, null)(Actions);
