import styled, { css } from 'styled-components';
import { Tabs, Text as SonarText, Input as SonarInput } from '@appcues/sonar';
import {
  FieldSet,
  Select,
  Label,
  RangeInput,
  Input,
  LightButton,
} from 'ext/components/ui';
import AngleInput, { Angle } from 'ext/components/AngleInput';

export const Content = styled.div`
  height: 100%;
  overflow-y: auto;

  ${Tabs.List} {
    background: var(--color-neutral-0);
    position: absolute;
    width: 100%;
    z-index: 1;
  }

  ${Tabs.Content} {
    padding-top: 51px;
  }

  ${Input}[type='number'] {
    &::-webkit-inner-spin-button {
      appearance: auto;
    }
  }

  ${SonarInput} {
    width: 100%;
  }
`;

export const Controls = styled.div`
  padding: 24px;

  ${Label} {
    color: var(--pleather);
    font-weight: var(--bold);
    line-height: 1.5;
  }

  ${RangeInput}, ${AngleInput} {
    > label {
      flex-grow: 1;
    }

    > input {
      padding: 8px 12px;
      text-align: left;
      height: 42px;
      outline: none;
      font-size: var(--regular);
      color: var(--pleather);
      min-width: 100px;
    }

    > input[type='number'] {
      &::-webkit-inner-spin-button {
        appearance: auto;
      }
    }
  }

  ${AngleInput} {
    input {
      min-width: 100px;
    }

    ${Angle} {
      margin: auto;
    }
  }
`;

export const GroupedFieldSet = styled(FieldSet)`
  display: flex;
  gap: 16px;
`;

const groupedFieldDefaultStyles = css`
  :nth-of-type(1) {
    flex-grow: 1;
  }

  :nth-of-type(2) {
    width: 100px;

    &:has(${LightButton}) {
      width: auto;
      align-self: end;
    }
  }
`;

const groupedFieldHalfStyles = css`
  width: 50%;

  ${Select} {
    min-width: auto;
  }
`;

export const GroupedField = styled.div`
  ${({ half }) => (half ? groupedFieldHalfStyles : groupedFieldDefaultStyles)}

  ${LightButton} {
    height: 42px;
    width: 42px;
    border: 1px solid;
  }
`;

export const Text = styled(SonarText)`
  color: var(--color-neutral-700);
  padding-bottom: var(--spacing-regular);
`;

export const RadioButtonLabel = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    width: 12px;
    margin-right: 6px;
    ${({ isBottom }) => isBottom && 'transform: rotate(180deg)'};
  }
`;
