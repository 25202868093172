import React from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import { assign } from 'object-path-immutable';
import { faRectangle } from '@fortawesome/pro-solid-svg-icons/faRectangle';
import { faCircle } from '@fortawesome/pro-solid-svg-icons/faCircle';
import { Accordion, Icon, Switch } from '@appcues/sonar';
import {
  FieldSet,
  RadioButton,
  RadioButtonGroup,
  Label,
} from 'ext/components/ui';
import {
  targetRectangleHighlightTemplate,
  emptyTargetRectangleHighlightTemplate,
  backdropCircleTemplate,
  backdropRectangleTemplate,
} from 'entities/step-children';
import { BACKDROP_KEYHOLE } from 'lib/trait';
import InputWithValidation from 'components/InputWithValidation';
import {
  Controls,
  GroupedField,
  GroupedFieldSet,
  RadioButtonLabel,
  useAccordionClick,
} from 'components/SideBarSettings/Shared';

const SHAPE = {
  CIRCLE: 'circle',
  RECTANGLE: 'rectangle',
};

export default function Highlight({
  backdropKeyhole,
  targetRectangle,
  handleTraitsUpdate,
}) {
  const trackAccordion = useAccordionClick();

  const {
    shape,
    blurRadius = 0,
    cornerRadius = 0,
  } = backdropKeyhole?.config || {};

  const { width = 0, height = 0 } = targetRectangle?.config || {};

  const resetTargetRectangle = (show = true) => {
    return assign(
      targetRectangle,
      'config',
      show
        ? targetRectangleHighlightTemplate
        : emptyTargetRectangleHighlightTemplate
    );
  };

  const handleBackdropShowChange = show => {
    const updatedTargetRectangle = resetTargetRectangle(show);

    if (show) {
      handleTraitsUpdate([updatedTargetRectangle, backdropCircleTemplate]);
    } else {
      handleTraitsUpdate(updatedTargetRectangle, BACKDROP_KEYHOLE);
    }
  };

  const handleShapeChange = shapeValue => {
    const emptyTargetRectangle = resetTargetRectangle();

    handleTraitsUpdate(
      shapeValue === SHAPE.CIRCLE
        ? [emptyTargetRectangle, backdropCircleTemplate]
        : [emptyTargetRectangle, backdropRectangleTemplate]
    );
  };

  const handleTraitChange = debounce((trait, patch) => {
    const updatedBackdropKeyhole = assign(trait, 'config', patch);
    handleTraitsUpdate(updatedBackdropKeyhole);
    // Decrease debounce time to avoid lose the previous change if the user
    // updates the left and top values faster than 500ms (default debounce time)
  }, 250);

  const handleTargetRectangle = (
    size,
    position,
    { target: { valueAsNumber } },
    isCircle
  ) => {
    const sizeValue = valueAsNumber;
    const positionValue = -(valueAsNumber / 2);

    handleTraitChange(targetRectangle, {
      [size]: sizeValue,
      [position]: positionValue,
      ...(isCircle && { height: sizeValue, y: positionValue }),
    });
  };

  const handleBackdropKeyHole = (key, value) => {
    handleTraitChange(backdropKeyhole, {
      [key]: Number(value),
    });
  };

  return (
    <Accordion.Item value="highlight">
      <Accordion.Header>
        <Accordion.Trigger
          onClick={() => trackAccordion('Tooltip', 'Highlight')}
        >
          Highlight
        </Accordion.Trigger>
      </Accordion.Header>
      <Accordion.Content>
        <Controls>
          <FieldSet>
            <Switch
              id="show"
              checked={!!backdropKeyhole}
              onCheckedChange={handleBackdropShowChange}
              fullWidth
            >
              <Switch.Label htmlFor="show">Show</Switch.Label>
            </Switch>
          </FieldSet>

          {!!backdropKeyhole && (
            <>
              <FieldSet>
                <Label>Shape</Label>
                <RadioButtonGroup>
                  <RadioButton
                    onClick={() => handleShapeChange(SHAPE.CIRCLE)}
                    selected={shape === SHAPE.CIRCLE}
                  >
                    <RadioButtonLabel>
                      <Icon icon={faCircle} />
                      Circle
                    </RadioButtonLabel>
                  </RadioButton>
                  <RadioButton
                    onClick={() => handleShapeChange(SHAPE.RECTANGLE)}
                    selected={shape === SHAPE.RECTANGLE}
                  >
                    <RadioButtonLabel>
                      <Icon icon={faRectangle} />
                      Rectangle
                    </RadioButtonLabel>
                  </RadioButton>
                </RadioButtonGroup>
              </FieldSet>

              {shape === SHAPE.CIRCLE && (
                <>
                  <FieldSet>
                    <Label htmlFor="highlight-size">Size</Label>
                    <InputWithValidation
                      id="highlight-size"
                      value={width}
                      onChange={event =>
                        handleTargetRectangle('width', 'x', event, SHAPE.CIRCLE)
                      }
                      type="number"
                      placeholder="0"
                      min="0"
                    />
                  </FieldSet>

                  <FieldSet>
                    <InputWithValidation
                      label="Feather"
                      value={blurRadius}
                      onChange={value =>
                        handleBackdropKeyHole('blurRadius', value)
                      }
                      max={100}
                      min={0}
                      range
                    />
                  </FieldSet>
                </>
              )}

              {shape === SHAPE.RECTANGLE && (
                <>
                  <GroupedFieldSet>
                    <GroupedField half>
                      <Label htmlFor="highlight-width">Width</Label>
                      <InputWithValidation
                        id="highlight-width"
                        value={width}
                        onChange={event =>
                          handleTargetRectangle('width', 'x', event)
                        }
                        type="number"
                        placeholder="0"
                        min="0"
                      />
                    </GroupedField>
                    <GroupedField half>
                      <Label htmlFor="highlight-height">Height</Label>
                      <InputWithValidation
                        id="highlight-height"
                        value={height}
                        onChange={event =>
                          handleTargetRectangle('height', 'y', event)
                        }
                        type="number"
                        placeholder="0"
                        min="0"
                      />
                    </GroupedField>
                  </GroupedFieldSet>

                  <FieldSet>
                    <InputWithValidation
                      label="Corner radius"
                      value={cornerRadius}
                      onChange={value =>
                        handleBackdropKeyHole('cornerRadius', value)
                      }
                      max={100}
                      min={0}
                      range
                    />
                  </FieldSet>
                </>
              )}
            </>
          )}
        </Controls>
      </Accordion.Content>
    </Accordion.Item>
  );
}

Highlight.propTypes = {
  backdropKeyhole: PropTypes.shape({
    config: PropTypes.shape({
      shape: PropTypes.string,
      blurRadius: PropTypes.number,
      cornerRadius: PropTypes.number,
    }),
  }),
  targetRectangle: PropTypes.shape({
    config: PropTypes.shape({
      width: PropTypes.number,
      height: PropTypes.number,
    }),
  }),
  handleTraitsUpdate: PropTypes.func,
};
