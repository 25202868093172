import React from 'react';
import PropTypes from 'prop-types';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft';
import { Button, Icon, SubTitle } from '@appcues/sonar';
import useAnalytics from 'ext/lib/hooks/use-analytics';
import { StyledBar, Header } from './styled';

const SideBar = ({ children, title, bannersShown, blockSelection }) => {
  const { track } = useAnalytics();
  const { selected, onClick } = blockSelection;

  const handleClick = () => {
    track('Mobile Builder interaction', {
      name: `Clicked Back from ${selected} Selection`,
      component: 'SideBar - Back Button',
    });
    onClick();
  };

  return (
    <StyledBar bannersShown={bannersShown}>
      <Header>
        {onClick && (
          <Button iconOnly onClick={handleClick} variant="tertiary">
            <Icon icon={faArrowLeft} />
          </Button>
        )}
        <SubTitle>{title}</SubTitle>
      </Header>
      {children}
    </StyledBar>
  );
};

SideBar.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string.isRequired,
  bannersShown: PropTypes.number,
  blockSelection: PropTypes.shape({
    selected: PropTypes.string,
    onClick: PropTypes.func,
  }),
};

export default SideBar;
