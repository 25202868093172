import React from 'react';
import PropTypes from 'prop-types';
import { Accordion } from '@appcues/sonar';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { BlockContentShape } from 'entities/block';
import { TEXT, BLOCK_LABELS } from 'lib/block';
import { Content, AlignmentSpacing } from 'components/SideBarSettings/Shared';
import FontStyle from './FontStyle';
import SmartText from './SmartText';

export function TextSettings({ content, onChange }) {
  const { id, style } = content ?? {};
  const { aiWritingAssistant } = useFlags();

  const handleChange = updatedStyle => {
    onChange({ blockId: id, contentChunk: updatedStyle });
  };

  return (
    <Content>
      <Accordion.Root
        collapsible="true"
        defaultValue={['font-style', 'alignment-spacing']}
        type="multiple"
      >
        <FontStyle content={content} onChange={handleChange} />
        <AlignmentSpacing
          style={style}
          spacingLabel={BLOCK_LABELS[TEXT]}
          onChange={handleChange}
          hasTextAlignment
          hasPadding
          hasMargin
        />
        {aiWritingAssistant && (
          <SmartText content={content} onChange={onChange} />
        )}
      </Accordion.Root>
    </Content>
  );
}

TextSettings.propTypes = {
  content: BlockContentShape,
  onChange: PropTypes.func,
};

export default TextSettings;
