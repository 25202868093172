/* eslint-disable no-restricted-imports */
import { combineReducers } from 'redux';
import { allstate, composeReducers } from 'ext/lib/collections';

/**
 * NOTE: these imports do NOT go through the index.js for each entity
 *
 * THIS IS INTENTIONAL. The reducers are not a public API, unlike
 * actions and selectors which ARE appropriate to import in components.
 */

import account from 'ext/entities/account/reducer';
import accounts from 'ext/entities/accounts/reducer';
import userProperties from 'ext/entities/user-properties/reducer';
import locales from 'ext/entities/locales/reducer';
import entitlements from 'ext/entities/entitlements/reducer';
import apps from 'entities/apps/reducer';
import auth from 'entities/auth/reducer';
import customComponents from 'entities/custom-components/reducer';
import experience from 'entities/experiences/reducer';
import experiencesSummary from 'entities/experiences/summary-reducer';
import historyLog from 'entities/history-log/reducer';
import screen from 'entities/screens/reducer';
import screensSummary from 'entities/screens/summary-reducer';
import screenshots from 'entities/screenshots/reducer';
import sdkToken from 'entities/screens/sdk-token-reducer';
import selected from 'entities/selected/reducer';
import slate from 'entities/slate/reducer';
import status from 'entities/status/reducer';
import stepChildren from 'entities/step-children/reducer';
import stepGroups from 'entities/step-groups/reducer';
import tags from 'entities/tags/reducer';
import templates from 'entities/templates/reducer';
import user from 'entities/user/reducer';
import userInterface from 'entities/user-interface/reducer';
import userPreferences from 'entities/user-preferences/reducer';

const identity = (state = null) => state;

const combined = combineReducers({
  account,
  accounts,
  apps,
  auth,
  customComponents,
  experience,
  'experiences-summary': experiencesSummary,
  locales,
  entitlements,
  screen,
  screenshots,
  'screens-summary': screensSummary,
  'sdk-token': sdkToken,
  slate,
  stepChildren,
  stepGroups,
  tags,
  templates,
  user,
  userInterface,
  userPreferences,
  userProperties,
  // Reducers enhanced with allState or that depend directly
  // on other reducers need to be defined at the end
  status,
  historyLog,
  selected: identity,
});

export default composeReducers(combined, allstate(selected, 'selected'));
