import React from 'react';
import PropTypes from 'prop-types';
import { Accordion } from '@appcues/sonar';
import { BlockContentShape } from 'entities/block';
import { Content, Style, SizeSpacing } from 'components/SideBarSettings/Shared';
import ContentActions from 'components/SideBarSettings/Content/ContentActions';
import { BUTTON } from 'lib/block';

export function ButtonSettings({ content, onChange }) {
  const { id: blockId } = content ?? {};

  return (
    <Content>
      <Accordion.Root
        collapsible="true"
        defaultValue={['style']}
        type="multiple"
      >
        <Style content={content} onChange={onChange} />
        <SizeSpacing
          content={content}
          onChange={onChange}
          hasMargin
          hasPadding
        />
        <ContentActions blockId={blockId} context={BUTTON} />
      </Accordion.Root>
    </Content>
  );
}

ButtonSettings.propTypes = {
  content: BlockContentShape,
  onChange: PropTypes.func,
};

export default ButtonSettings;
