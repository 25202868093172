/* globals CUSTOMER_API_URL, APPCUES_API_URL, IMAGE_SERVICE_LAMBDA_URL */

import HTTPError from 'ext/lib/exceptions/http';
import { CUSTOM_COMPONENT_TYPE } from 'entities/custom-components';
import { CONTENT_TEMPLATE_TYPE } from 'lib/templates';
import fixtures from './fixtures-client';

/**
 * Create authenticated fetch client for provided base URL
 *
 * @param {string} baseUrl - Base URL of resource
 * @param {object} auth - Auth object
 * @param {string} auth.accountId - Account ID
 * @param {string} auth.jwt - User JWT
 * @returns {function} Pre-constructed fetch client
 */
export const createFetch =
  (baseUrl, { accountId, jwt }) =>
  async (
    path,
    {
      account = true,
      contentType = 'application/json',
      parse = true,
      responseType,
      ...options
    } = {}
  ) => {
    const base = account ? `${baseUrl}/accounts/${accountId}` : baseUrl;
    const response = await fetch(`${base}/${path}`, {
      ...options,
      headers: {
        ...options.headers,
        'Content-Type': contentType,
        Authorization: `Bearer ${jwt}`,
      },
    });

    if (!response.ok) {
      const error = await response.json();
      throw new HTTPError(JSON.stringify(error));
    }

    if (responseType === 'blob') {
      return response.blob();
    }

    const text = await response.text();

    return text && text.length > 0 ? (parse ? JSON.parse(text) : text) : null;
  };

/**
 * Create API client with provided auth
 *
 * @param {object} auth - Auth object
 * @param {string} auth.accountId - Account ID
 * @param {string} auth.jwt - User JWT
 * @returns {APIClient}
 */
export const create = ({ accountId, jwt }) => {
  const auth = { accountId, jwt };

  const fetchCustomerAPI = createFetch(`${CUSTOMER_API_URL}/v1`, auth);
  const fetchCustomerAPIv2 = createFetch(`${CUSTOMER_API_URL}/v2`, auth);
  const fetchDashboardAPI = createFetch(`${APPCUES_API_URL}/v1`, auth);
  const imageLambdaService = createFetch(`${IMAGE_SERVICE_LAMBDA_URL}`, auth);

  return {
    // Accounts API methods
    getUserAccounts: userId =>
      fetchCustomerAPI(`users/${userId}/accounts`, { account: false }),

    getAccount: () => fetchCustomerAPIv2(''),

    // Apps API methods
    getApps: () => fetchCustomerAPI('apps'),

    // Customer API methods
    getUser: id => fetchCustomerAPI(`users/${id}`, { account: false }),

    getUserProperties: () => fetchDashboardAPI('profile_attributes'),

    // Experience API methods
    getExperience: id => fetchCustomerAPI(`experiences/${id}`),

    getExperiencesSummary: () =>
      fetchCustomerAPI('experiences?type=mobile,mobile_embed&summary=true'),

    createExperience: experience =>
      fetchCustomerAPI('experiences?template=mobile', {
        method: 'POST',
        body: JSON.stringify(experience),
      }),

    updateExperience: (id, patch, replace = false) =>
      fetchCustomerAPI(
        `experiences/${id}${replace ? '?replace_steps=true' : ''}`,
        {
          method: 'PATCH',
          body: JSON.stringify(patch),
        }
      ),

    // Step API methods
    createStep: (id, body) =>
      fetchCustomerAPI(`experiences/${id}/steps`, {
        method: 'POST',
        body: JSON.stringify(body),
      }),

    addStep: (id, stepId, body) =>
      fetchCustomerAPI(`experiences/${id}/steps/${stepId}/add`, {
        method: 'POST',
        body: JSON.stringify(body),
      }),

    updateStep: (id, stepId, delta) =>
      fetchCustomerAPI(`experiences/${id}/steps/${stepId}`, {
        method: 'PATCH',
        body: JSON.stringify(delta),
      }),

    moveStep: (id, stepId, patch) =>
      fetchCustomerAPI(`experiences/${id}/steps/${stepId}/move`, {
        method: 'PATCH',
        body: JSON.stringify(patch),
      }),

    removeStep: (experienceId, stepId) =>
      fetchCustomerAPI(`experiences/${experienceId}/steps/${stepId}`, {
        method: 'DELETE',
      }),

    // Screen capture API methods
    getScreen: (appId, screenId) =>
      fetchCustomerAPI(`mobile/${appId}/screens/${screenId}`),

    getScreensSummary: id =>
      fetchCustomerAPI(`mobile/${id}/screens?summary=true&state=ALL`),

    getSDKToken: () =>
      fetchCustomerAPI('web/custom-session?role=mobile-sdk', {
        method: 'POST',
        account: false,
      }),

    updateScreen: (appId, screenId, patch) =>
      fetchCustomerAPI(`mobile/${appId}/screens/${screenId}`, {
        method: 'PATCH',
        body: JSON.stringify(patch),
      }),

    removeScreen: (appId, screenId) =>
      fetchCustomerAPI(`mobile/${appId}/screens/${screenId}`, {
        method: 'DELETE',
      }),

    // Tags API methods
    getTags: () => fetchCustomerAPI('tags'),

    // Templates API methods
    getTemplates: () =>
      fetchCustomerAPI(`content_templates?type=${CONTENT_TEMPLATE_TYPE}`),
    createTemplate: body =>
      fetchCustomerAPI('content_templates', {
        method: 'POST',
        body: JSON.stringify(body),
      }),
    updateTemplate: (templateId, body) =>
      fetchCustomerAPI(`content_templates/${templateId}`, {
        method: 'PATCH',
        body: JSON.stringify(body),
      }),
    removeTemplate: templateId =>
      fetchCustomerAPI(`content_templates/${templateId}`, {
        method: 'DELETE',
      }),

    // Custom component API methods
    getCustomComponents: () =>
      fetchCustomerAPI(`content_templates?type=${CUSTOM_COMPONENT_TYPE}`),
    createCustomComponent: body =>
      fetchCustomerAPI('content_templates', {
        method: 'POST',
        body: JSON.stringify(body),
      }),
    updateCustomComponent: (componentId, body) =>
      fetchCustomerAPI(`content_templates/${componentId}`, {
        method: 'PATCH',
        body: JSON.stringify(body),
      }),
    removeCustomComponent: componentId =>
      fetchCustomerAPI(`content_templates/${componentId}`, {
        method: 'DELETE',
      }),

    // Locales API methods
    getLocales: () => fetchCustomerAPI('locales'),

    createLocale: body =>
      fetchCustomerAPI('locales', {
        method: 'POST',
        body: JSON.stringify(body),
      }),

    removeLocale: localeId =>
      fetchCustomerAPI(`locales/${localeId}`, {
        method: 'DELETE',
      }),

    updateLocale: (localeId, body) =>
      fetchCustomerAPI(`locales/${localeId}`, {
        method: 'PATCH',
        body: JSON.stringify(body),
      }),

    // Translation API methods
    autoTranslate: xliff =>
      fetchCustomerAPI('assistant/translate-xliff', {
        method: 'POST',
        body: xliff,
        account: true,
        contentType: 'application/xml',
        parse: false,
      }),

    generateSmartText: (prompt, text) => {
      return Promise.resolve(
        fetchCustomerAPI(`assistant/rewrite?prompt=${prompt}`, {
          method: 'POST',
          body: JSON.stringify({ text }),
          account: true,
        })
      );
    },

    // Image service methods
    getLambdaTemplateImage: templateId =>
      imageLambdaService(`?accountId=${accountId}&templateId=${templateId}`, {
        responseType: 'blob',
        account: false,
      }),

    // get entitlements
    getEntitlements: () => fetchCustomerAPIv2('entitlements'),

    // Fixture overrides
    ...fixtures,
  };
};
