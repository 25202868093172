import { assign, get } from 'object-path-immutable';
import { LOCALIZED } from './block';
import { getNodePath } from './get-node-path';

/**
 * Get element by id
 * @param {object} content - root content of an step children
 * @param {string} nodeId - nodeId (can be a stack or block)
 * @returns {object} Element object
 */
export const getElement = (content, nodeId) => {
  const nodePath = getNodePath(content, nodeId);
  return nodePath ? get(content, nodePath) : null;
};

/**
 * Get parent id and blockType
 * @param {object} content - root content of an step children
 * @param {string} blockId - child block id
 * @return {{string, string}} Parent id and blockType
 */
// eslint-disable-next-line consistent-return
export const getParentBlock = (content, blockId) => {
  const parentNodePath = getNodePath(content, blockId) ?? [];

  // Iterate on parentNodePath
  while (parentNodePath.length > 0) {
    const parentNode = get(content, parentNodePath);
    const { id, blockType } = parentNode;

    // As soon as we find a blockType, return it
    if (blockId !== id && blockType && blockType !== LOCALIZED) {
      return { id, blockType };
    }

    // If we don't have valid type and blockType
    // we go up one level
    parentNodePath.pop();
  }
};

/**
 * Update element in content tree
 * @param {object} content - root content of an step children
 * @param {string} blockId - block id inside items array
 * @param {object} contentChunk - content chunk with updates
 * @return {object} Updated content tree with updated element
 */
export const updateElement = ({ content, blockId, contentChunk }) => {
  if (!blockId) return content;

  const updatedContent = assign(
    content,
    getNodePath(content, blockId),
    contentChunk
  );

  return { content: updatedContent };
};
