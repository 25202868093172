import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import {
  DistributionShape,
  OrientationShape,
  StyleShape,
} from 'entities/step-children';
import { THEMES } from 'lib/user-preferences';
import { transformStyles } from 'components/Editor/Primitives';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledStack = styled.div.attrs(({ id }) => ({
  'data-apc-type': 'stack',
  'data-testid': id,
}))`
  display: flex;
  position: relative;
  flex: 1;
  flex-direction: ${props =>
    props.orientation === 'horizontal' ? 'row' : 'column'};
  ${({ spacing }) => spacing && `gap: ${spacing}px`}

  align-items: ${({ orientation }) =>
    orientation === 'horizontal' && 'stretch'};

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    ${({ backgroundStyles }) => css(backgroundStyles)}
  }
`;

export default function Stack({
  id,
  orientation,
  distribution,
  spacing,
  style,
  theme,
  children,
  className,
}) {
  // The margin on iOS shrinks the image, behaving more like padding on Web
  // so we need to remap the margin to padding so it looks similar on preview.
  // We break those styles and pass to the frame, so the border stays on image
  // but the padding happens outside
  const {
    alignItems,
    alignSelf,
    marginTop: paddingTop,
    marginBottom: paddingBottom,
    marginLeft: paddingLeft,
    marginRight: paddingRight,
    backgroundColor,
    backgroundImage,
    backgroundPosition,
    backgroundRepeat,
    backgroundSize,
    width,
    ...styles
  } = transformStyles(style, theme);

  const backgroundStyles = {
    backgroundColor,
    backgroundImage,
    backgroundPosition,
    backgroundRepeat,
    backgroundSize,
    width,
  };

  return (
    <Wrapper
      style={{
        paddingTop,
        paddingBottom,
        paddingLeft,
        paddingRight,
      }}
    >
      <StyledStack
        id={id}
        orientation={orientation}
        distribution={distribution}
        spacing={spacing}
        className={className}
        backgroundStyles={backgroundStyles}
        style={styles}
      >
        {children}
      </StyledStack>
    </Wrapper>
  );
}

Stack.propTypes = {
  id: PropTypes.string.isRequired,
  orientation: OrientationShape.isRequired,
  distribution: DistributionShape,
  spacing: PropTypes.number,
  style: StyleShape,
  theme: PropTypes.oneOf(THEMES),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};
