import React from 'react';
import PropTypes from 'prop-types';
import { faPalette } from '@fortawesome/free-solid-svg-icons/faPalette';
import { faShare } from '@fortawesome/free-solid-svg-icons/faShare';
import { Accordion, Tabs } from '@appcues/sonar';
import { EditorShape } from 'entities/step-groups';
import { ContentShape } from 'entities/step-children';
import { STANDARD, SLIDEOUT } from 'lib/presentation';
import { Shape as ActionsShape } from 'lib/actions';
import { hasSurveyBlockType } from 'lib/block';
import { THEMES } from 'lib/user-preferences';
import {
  Content,
  AlignmentSpacing,
  Container,
  Navigation,
  DropShadow,
  Backdrop,
  Effects,
} from 'components/SideBarSettings/Shared';
import Details from './Details';
import Position from './Position';

export function ModalSettings({
  selectedStepGroup,
  isStepGroupFirstChild,
  editor,
  content,
  stepChildrenIds = [],
  actions,
  theme,
  handleActionsUpdate,
  onChange,
}) {
  const { presentation, style } = editor;
  const isSingleStep = stepChildrenIds.length === 1;
  const hasSurveyBlock = hasSurveyBlockType(content);

  const StyleContent = (
    <Accordion.Root
      collapsible="true"
      defaultValue={['details', 'container']}
      type="multiple"
    >
      <Details
        editor={editor}
        isSingleStep={isSingleStep}
        hasSurveyBlock={hasSurveyBlock}
        onChange={onChange}
      />

      <Container editor={editor} theme={theme} onChange={onChange} />

      {presentation === SLIDEOUT && (
        <Position editor={editor} onChange={onChange} />
      )}

      {[STANDARD, SLIDEOUT].includes(presentation) && (
        <>
          <DropShadow editor={editor} theme={theme} onChange={onChange} />

          <Backdrop editor={editor} theme={theme} onChange={onChange} />

          <Effects
            key={selectedStepGroup}
            editor={editor}
            stepGroup={selectedStepGroup}
            onChange={onChange}
          />
        </>
      )}

      <AlignmentSpacing
        style={style}
        spacingLabel="Content"
        onChange={onChange}
        hasPadding
      />
    </Accordion.Root>
  );

  const ModalTabs = (
    <Tabs.Root defaultValue="style" align="stretch">
      <Tabs.List>
        <Tabs.Trigger value="style" icon={faPalette}>
          Style
        </Tabs.Trigger>
        <Tabs.Trigger value="navigation" icon={faShare}>
          Navigation
        </Tabs.Trigger>
      </Tabs.List>
      <Tabs.Content value="style">{StyleContent}</Tabs.Content>
      <Tabs.Content value="navigation">
        <Navigation
          stepGroup={selectedStepGroup}
          actions={actions}
          handleActionsUpdate={handleActionsUpdate}
        />
      </Tabs.Content>
    </Tabs.Root>
  );

  return <Content>{isStepGroupFirstChild ? ModalTabs : StyleContent}</Content>;
}

ModalSettings.propTypes = {
  selectedStepGroup: PropTypes.string,
  isStepGroupFirstChild: PropTypes.bool,
  editor: EditorShape,
  content: ContentShape,
  stepChildrenIds: PropTypes.arrayOf(PropTypes.string),
  actions: ActionsShape,
  theme: PropTypes.oneOf(THEMES),
  onChange: PropTypes.func,
  handleActionsUpdate: PropTypes.func,
};

export default ModalSettings;
