import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Accordion } from '@appcues/sonar';
import { FieldSet, Label } from 'ext/components/ui';
import { BlockContentShape } from 'entities/block';
import { selectUserPreferences } from 'entities/user-preferences';
import { BUTTON, BLOCK_LABELS } from 'lib/block';
import { THEMES } from 'lib/user-preferences';
import ColorInput from 'components/ColorInput';
import InputWithValidation from 'components/InputWithValidation/';
import useStyleSettings from './use-style-settings';
import useAccordionClick from './use-accordion-click';
import { Controls, GroupedField, GroupedFieldSet } from './styled';

const Style = ({ content, onChange, theme }) => {
  const trackAccordion = useAccordionClick();

  const { id, style, type } = content ?? {};
  const isButton = type === BUTTON;

  const handleChange = useCallback(
    patch => {
      onChange({
        blockId: id,
        contentChunk: { style: patch },
        useOriginalId: isButton,
      });
    },
    [id, isButton, onChange]
  );

  const [valueFor, handleChangeFor] = useStyleSettings({
    onChange: handleChange,
    style,
    theme,
  });

  return (
    <Accordion.Item value="style">
      <Accordion.Header>
        <Accordion.Trigger
          onClick={() => trackAccordion(BLOCK_LABELS[type], 'Style')}
        >
          Style
        </Accordion.Trigger>
      </Accordion.Header>
      <Accordion.Content>
        <Controls>
          {isButton && (
            <FieldSet>
              <Label htmlFor="button-color">Color</Label>
              <ColorInput
                id="button-color"
                color={valueFor.backgroundColor}
                onChange={handleChangeFor.backgroundColor}
              />
            </FieldSet>
          )}

          <GroupedFieldSet>
            <GroupedField>
              <Label htmlFor="border-color">Border color</Label>
              <ColorInput
                id="border-color"
                color={valueFor.borderColor}
                onChange={handleChangeFor.borderColor}
              />
            </GroupedField>

            <GroupedField>
              <Label htmlFor="thickness">Thickness</Label>
              <InputWithValidation
                id="thickness"
                value={valueFor.borderWidth}
                onChange={handleChangeFor.borderWidth}
                type="number"
                placeholder="0"
                min="0"
              />
            </GroupedField>
          </GroupedFieldSet>

          <FieldSet>
            <InputWithValidation
              label="Corner radius"
              value={valueFor.cornerRadius}
              onChange={handleChangeFor.cornerRadius}
              max={100}
              min={0}
              range
            />
          </FieldSet>
        </Controls>
      </Accordion.Content>
    </Accordion.Item>
  );
};

Style.propTypes = {
  content: BlockContentShape,
  onChange: PropTypes.func,
  theme: PropTypes.oneOf(THEMES),
};

const mapStateToProps = state => ({
  theme: selectUserPreferences(state).theme,
});

export default connect(mapStateToProps)(Style);
