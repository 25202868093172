import React from 'react';
import PropTypes from 'prop-types';
import { Accordion } from '@appcues/sonar';
import { Label, Select } from 'ext/components/ui';
import { BlockContentShape } from 'entities/block';
import {
  RATING_NUMBER,
  RATING_STAR,
  RATING_EMOJI,
  BLOCK_MODES,
} from 'lib/block';
import {
  Controls,
  GroupedFieldSet,
  GroupedField,
  useAccordionClick,
} from 'components/SideBarSettings/Shared';
import { RequiredField } from 'components/SideBarSettings/Survey';
import {
  generateArrayRange,
  generateDiplayFormat,
  generateOptions,
} from './generators';

const [HORIZONTAL, VERTICAL, NPS] = BLOCK_MODES[RATING_NUMBER];

export default function RatingBehavior({
  ratingType,
  displayFormat,
  options = [],
  onChange,
  onRequiredChange,
  onErrorLabelChange,
}) {
  const trackAccordion = useAccordionClick();
  const [optionDefault] = options;

  const isNumber = ratingType === RATING_NUMBER;

  const minValueFromOption = isNumber ? Number(optionDefault.value) : 1;
  const maxValueFromOption = isNumber
    ? options.slice(-1)[0].value
    : Math.min(options.length, 5);
  const maxOptions = isNumber ? 10 : 5;

  const minSelectOptions = generateArrayRange(0, maxOptions)
    .map((_, index) => ({
      label: `${index}`,
      value: index,
    }))
    .filter(({ value }) => value <= maxValueFromOption);

  const maxSelectOptions = generateArrayRange(
    minValueFromOption,
    isNumber ? minValueFromOption + maxOptions : 5
  ).map((_, index) => ({
    label: `${minValueFromOption + index}`,
    value: minValueFromOption + index,
  }));

  const handleMinValueChange = ({ value }) => {
    let maxValue = maxValueFromOption;

    // We should not have more options than maxOptions
    // so if the minValue is lower, we cap the maxValue
    if (maxValueFromOption - value > maxOptions) {
      maxValue = value + maxOptions;
    }

    const updatedOptions = generateOptions({
      startFrom: value,
      maxValue,
      option: optionDefault,
      type: ratingType,
      displayFormat,
      mergeStyles: false,
    });

    onChange({
      options: updatedOptions,
      displayFormat: generateDiplayFormat(updatedOptions, displayFormat),
    });
  };

  const handleMaxValueChange = ({ value }) => {
    const updatedOptions = generateOptions({
      startFrom: minValueFromOption,
      maxValue: value,
      option: optionDefault,
      type: ratingType,
      displayFormat,
      mergeStyles: false,
    });

    onChange({
      options: updatedOptions,
      displayFormat: generateDiplayFormat(updatedOptions, displayFormat),
    });
  };

  return (
    <Accordion.Item value="behavior">
      <Accordion.Header>
        <Accordion.Trigger onClick={() => trackAccordion('Rating', 'Behavior')}>
          Behavior
        </Accordion.Trigger>
      </Accordion.Header>
      <Accordion.Content>
        <Controls>
          <GroupedFieldSet>
            {isNumber && (
              <GroupedField half>
                <Label htmlFor="min-value">Minimum value</Label>
                <Select
                  id="min-value"
                  aria-label="Minimum value"
                  options={minSelectOptions}
                  value={{
                    label: `${minValueFromOption}`,
                    value: minValueFromOption,
                  }}
                  onChange={handleMinValueChange}
                  portal
                />
              </GroupedField>
            )}
            <GroupedField half={isNumber}>
              <Label htmlFor="max-value">Maximum value</Label>
              <Select
                id="max-value"
                aria-label="Maximum value"
                options={maxSelectOptions}
                value={{
                  label: `${maxValueFromOption}`,
                  value: maxValueFromOption,
                }}
                onChange={handleMaxValueChange}
                portal
              />
            </GroupedField>
          </GroupedFieldSet>

          <RequiredField
            onRequiredChange={onRequiredChange}
            onErrorLabelChange={onErrorLabelChange}
          />
        </Controls>
      </Accordion.Content>
    </Accordion.Item>
  );
}

RatingBehavior.propTypes = {
  ratingType: PropTypes.oneOf([RATING_STAR, RATING_EMOJI, RATING_NUMBER]),
  displayFormat: PropTypes.oneOf([HORIZONTAL, VERTICAL, NPS]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      content: BlockContentShape,
      selectedContent: BlockContentShape,
      value: PropTypes.string,
    })
  ),
  onChange: PropTypes.func,
  onRequiredChange: PropTypes.func,
  onErrorLabelChange: PropTypes.func,
};
