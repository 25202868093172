import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import {
  selectStepChild,
  ContentShape,
  StyleShape,
} from 'entities/step-children';
import { selectSelected } from 'entities/selected';
import { focus, BlockContentShape } from 'entities/block';
import { getParentBlock } from 'lib/block';
import { THEMES } from 'lib/user-preferences';
import {
  interactionStyles,
  transformStyles,
} from 'components/Editor/Primitives';
import { TextContainer } from 'components/Editor/Containers';
import handleErrorStyle from './error-style-handler';

const TextFieldContainer = styled.div`
  ${({ isParentSelected, isSelected }) =>
    interactionStyles({
      unreach: !isParentSelected,
      selected: isSelected,
      hovered: !isSelected,
    })}
`;

const DEFAULT_FIELD_HEIGHT = 42;

const TextFieldBox = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;

  ${({ numberOfLines }) =>
    numberOfLines === 1
      ? css`
          min-height: ${DEFAULT_FIELD_HEIGHT}px;
        `
      : css`
          display: block;
          min-height: ${DEFAULT_FIELD_HEIGHT * numberOfLines}px;
        `}
`;

const InputText = styled.p`
  width: 100%;
  margin: 0px;
  word-break: break-word;
`;

const TextField = ({
  id,
  placeholder,
  numberOfLines,
  textFieldStyle,
  previewInputColor,
  error,
  theme,
  content,
  selectedBlock,
  onClick,
}) => {
  const { id: parentId } = getParentBlock(content, id) ?? {};
  const isParentSelected = selectedBlock === parentId;
  const isSelected = id === selectedBlock;

  const {
    marginTop,
    marginBottom,
    marginLeft,
    marginRight,
    color,
    inputTextStyles = { color },
    ...textFieldStyles
  } = transformStyles(textFieldStyle, theme);

  const { previewErrorColor, errorColor } = error;
  const textFieldBoxStyles = previewErrorColor
    ? handleErrorStyle({
        style: textFieldStyles,
        key: 'borderColor',
        color: errorColor,
      })
    : textFieldStyles;

  const textFieldContainerStyles = {
    marginTop,
    marginBottom,
    marginLeft,
    marginRight,
  };

  const {
    id: placeholderId,
    text: placeholderText,
    style: placeholderStyle,
  } = placeholder;

  const handleClick = () => {
    onClick(id);
  };

  const handleTextFieldContent = () =>
    previewInputColor ? (
      <InputText style={inputTextStyles}>This is some input text</InputText>
    ) : (
      <TextContainer
        id={placeholderId}
        wrapperId={id}
        text={placeholderText}
        style={placeholderStyle}
        theme={theme}
      />
    );

  return (
    <TextFieldContainer
      isSelected={isSelected}
      isParentSelected={isParentSelected}
      style={textFieldContainerStyles}
    >
      <TextFieldBox
        numberOfLines={numberOfLines}
        style={textFieldBoxStyles}
        onClick={handleClick}
      >
        {handleTextFieldContent()}
      </TextFieldBox>
    </TextFieldContainer>
  );
};

TextField.propTypes = {
  id: PropTypes.string,
  placeholder: BlockContentShape,
  numberOfLines: PropTypes.number,
  textFieldStyle: StyleShape,
  previewInputColor: PropTypes.bool,
  error: PropTypes.shape({
    previewErrorColor: PropTypes.bool,
    errorColor: PropTypes.string,
  }),
  theme: PropTypes.oneOf(THEMES),
  content: ContentShape,
  selectedBlock: PropTypes.string,
  onClick: PropTypes.func,
};

const mapStateToProps = state => {
  const { stepChild: stepId, block: selectedBlock } =
    selectSelected(state) ?? {};
  const { content } = selectStepChild(state, stepId) ?? {};

  return { content, selectedBlock };
};

const mapDispatchToProps = {
  onClick: focus,
};

export default connect(mapStateToProps, mapDispatchToProps)(TextField);
