import styled from 'styled-components';
import Tether from 'ext/components/Tether';

// This is needed so the ActionsToolbar doesn't
// get over other elements as modals.
export const StyledTether = styled(Tether)`
  &&& {
    z-index: 0;
  }
`;

export const BlockSelection = styled.div`
  width: 100%;
  position: relative;
  opacity: ${({ isDragging }) => (isDragging ? 0 : 1)};
  cursor: grab;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 4px;
    background: var(--color-blue-500);
    opacity: ${({ isOver }) => (isOver ? 1 : 0)};

    ${({ direction }) => `
      ${direction === 'right' ? 'inset-inline-end: 0' : 'inset-inline-start: 0'}
    `}
  }

  &[data-selected='true'] {
    display: block;
    outline: 2px solid var(--color-blue-500);
    outline-offset: -2px;
  }

  &:hover:not([data-selected='true']) {
    display: block;
    background: var(--color-blue-100-opacity-50);
    outline: 1px dashed var(--color-blue-500);
  }

  [contenteditable]:focus-visible {
    outline: none;
  }

  [data-tether='wrapped'] {
    display: grid;
    height: 100%;
  }
`;

export const BlockOverlay = styled.div`
  background-color: var(--myst);
  display: flex;
  flex: 1;
  position: absolute;
  top: 0;
  left: ${({ left }) => (left ? `${left}px` : 0)};

  ${({ height, width }) => {
    const margin = 12;
    return (
      height &&
      width &&
      `
        height: ${height - margin * 2}px;
        width: ${width - margin * 2}px;
        margin: ${margin}px;
      `
    );
  }}
`;
