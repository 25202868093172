/* global MOBILE_BUILDER_URL */

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Heading, Modal, ModalHeader } from 'ext/components/ui';
import useAnalytics from 'ext/lib/hooks/use-analytics';
import { isPreviewBuild } from 'lib/location';
import { TYPES } from 'components/PreviewProxy';
import QRCodeInput from 'components/QRCodeInput';

export const LaunchDebuggerModal = ({ appId, visible, onClose }) => {
  const { track } = useAnalytics();

  const debuggerUrl = useMemo(() => {
    const { pathname } = window.location;
    const hash = isPreviewBuild(pathname) ? `${pathname}#` : '';
    return `${MOBILE_BUILDER_URL}${hash}?type=${TYPES.DEBUGGER}&appId=${appId}`;
  }, [appId]);

  const handleClick = () => {
    track('Mobile Builder interaction', {
      name: 'Clicked Done Launch Debugger Modal',
      component: 'LaunchDebuggerModal',
    });
    onClose();
  };

  return (
    <Modal visible={visible} onClose={onClose} theme="light">
      <ModalHeader>
        <Heading>Launch debugger</Heading>
        <ModalHeader.Close onClose={onClose} />
      </ModalHeader>
      <QRCodeInput
        ariaLabel="Launch debugger"
        url={debuggerUrl}
        onClick={handleClick}
      />
    </Modal>
  );
};

LaunchDebuggerModal.propTypes = {
  appId: PropTypes.string,
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default LaunchDebuggerModal;
