import React from 'react';
import PropTypes from 'prop-types';
import { Accordion } from '@appcues/sonar';
import { VIDEO, BLOCK_LABELS } from 'lib/block';
import { Shape as BlockContentShape } from 'entities/block';
import { Content, AlignmentSpacing } from 'components/SideBarSettings/Shared';
import Video from './Video';

export function VideoSettings({ content, onChange }) {
  const { id, style } = content ?? {};

  const handleChange = updatedStyle => {
    onChange({ blockId: id, contentChunk: updatedStyle });
  };

  return (
    <Content>
      <Video content={content} onChange={onChange} />

      <Accordion.Root
        collapsible="true"
        defaultValue={['alignment-spacing']}
        type="multiple"
      >
        <AlignmentSpacing
          style={style}
          content={content}
          spacingLabel={BLOCK_LABELS[VIDEO]}
          onChange={handleChange}
          hasMargin
        />
      </Accordion.Root>
    </Content>
  );
}

VideoSettings.propTypes = {
  content: BlockContentShape,
  onChange: PropTypes.func,
};

export default VideoSettings;
