import React from 'react';
import PropTypes from 'prop-types';
import { Accordion } from '@appcues/sonar';
import { BlockContentShape } from 'entities/block';
import { DEFAULT_LOCALE } from 'lib/localization';
import { mergeProperties } from 'utils/object-handler';
import { Content } from 'components/SideBarSettings/Shared';
import FontStyle from 'components/SideBarSettings/Content/TextSettings/FontStyle';

export function TextOptionsSettings({
  content,
  contentWithVariations,
  localeId,
  onChange,
}) {
  const { id, options } = content ?? {};
  const { content: contentOption } = options[0] ?? {};
  const { options: optionsWithVariations } = contentWithVariations ?? {};

  const handleChange = updatedValue => {
    const { style } = updatedValue ?? {};

    // When has no variations, we update `content` key
    // when has variations but it's default locale, we update `content.content` key
    // otherwise, we update `content.variations[localeId]` key
    const keyToUpdate =
      localeId === DEFAULT_LOCALE.id
        ? ['content', 'content.content']
        : [`content.variations.${localeId}`];

    const updatedOptions = optionsWithVariations.map(option =>
      mergeProperties(option, keyToUpdate, { style })
    );

    onChange({
      blockId: id,
      contentChunk: { options: updatedOptions },
      useOriginalId: true,
    });
  };

  return (
    <Content>
      <Accordion.Root
        collapsible="true"
        defaultValue={['font-style', 'alignment-spacing']}
        type="multiple"
      >
        <FontStyle
          content={contentOption}
          onChange={handleChange}
          hideLineHeight
        />
      </Accordion.Root>
    </Content>
  );
}

TextOptionsSettings.propTypes = {
  content: BlockContentShape,
  contentWithVariations: BlockContentShape,
  localeId: PropTypes.string,
  onChange: PropTypes.func,
};

export default TextOptionsSettings;
