import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  StyleSheetManager,
  ThemeProvider,
  createGlobalStyle,
} from 'styled-components';
import { dom } from '@fortawesome/fontawesome-svg-core';
import sonarVariables from '@appcues/sonar-tokens/dist/variables.css';
import darkSonarVariables from '@appcues/sonar-tokens/dist/variables.dark.css';
import sonarBuilderStyles from '@appcues/sonar-builder/dist/sonar-builder.css';
import css from 'ext/styles/app.css';
import sonarOverrides from 'ext/styles/sonarOverrides.css';
import variables from 'ext/styles/variables.css';

const GlobalStyle = createGlobalStyle`
  ${dom.css()}
  ${css.toString()}
  ${variables.toString()}
  ${sonarVariables.toString()}
  ${darkSonarVariables.toString()}
  ${sonarOverrides.toString()}
`;

const TailwindStyles = createGlobalStyle`
  ${sonarBuilderStyles.toString()}
`;

/**
 * Find owner document if target exists, otherwise fallback to current
 * window's document. This is to ensure we capture the correct document
 * context when providing styles within an iframe
 *
 * @param {(ShadowRoot|HTMLElement)} target - Target element
 * @return {Document}
 */
const getDocument = target => {
  const { ownerDocument = window.document } = target || {};
  return ownerDocument;
};

/**
 * Inject and load Google Font into host page site so that it can used within
 * ShadowDOM. If target is an HTMLElement, specifically an `iframe`, we can just
 * inject it into it's owner document so that it can access the fonts
 *
 * @param {(ShadowRoot|HTMLElement)} target - Target element
 * @return {void}
 */
const useGoogleFont = target => {
  useEffect(() => {
    const document = getDocument(target);

    if (!document.querySelector('[data-injected-style]')) {
      const $font = document.createElement('link');
      $font.type = 'text/css';
      $font.rel = 'stylesheet';
      $font.dataset.injectedStyle = true;
      $font.href =
        '//fonts.googleapis.com/css2?family=Mulish:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap';
      document.head.append($font);
    }
  }, [target]);
};

/**
 * Prevent focus outline styles from being applied until the user explicitly
 * presses `TAB`. This way if the user is primarily navigating around with a
 * mouse we can make things look "nicer" and switch to a11y mode on demand.
 *
 * @param {(ShadowRoot|HTMLElement)} target - Target element
 * @return {void}
 */
const useFocusOutline = target => {
  useEffect(() => {
    const document = getDocument(target);

    const selector = 'style#no-focus-outline';

    if (target && !target.querySelector(selector)) {
      const $style = document.createElement('style');
      $style.id = 'no-focus-outline';
      $style.innerHTML = `
        a:focus,
        button:focus,
        input:focus,
        textarea:focus {
          outline: none;
        }
      `;
      target.append($style);

      const handler = ({ key }) => {
        if (key === 'Tab') {
          target.querySelector(selector).remove();
          target.removeEventListener('keyup', handler);
        }
      };

      target.addEventListener('keyup', handler);
    }
  }, [target]);
};

const StyleProvider = ({ children, global = true, target, pattern }) => {
  useGoogleFont(target);
  useFocusOutline(target);

  return (
    <StyleSheetManager target={target}>
      <ThemeProvider theme={{}}>
        <>
          {global && <GlobalStyle />}
          {(pattern === 'flow' || pattern === 'storybook') && (
            <TailwindStyles />
          )}
          {children}
        </>
      </ThemeProvider>
    </StyleSheetManager>
  );
};

StyleProvider.propTypes = {
  children: PropTypes.node,
  global: PropTypes.bool,
  target: PropTypes.oneOfType([
    PropTypes.instanceOf(ShadowRoot),
    /**
     * NOTE: Since HTMLElement instances differs based on context, using the
     * instance defined in this window will not match the instance in e.g. an
     * iframe which is why we just use the generic object type...
     */
    PropTypes.object,
  ]),
  pattern: PropTypes.string,
};

export default StyleProvider;
