import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Accordion, Switch } from '@appcues/sonar';
import { Label, FieldSet } from 'ext/components/ui';
import { Shape as BlockContentShape } from 'entities/block';
import { selectUserPreferences } from 'entities/user-preferences';
import { getLocaleVariations, updateToLocalized } from 'lib/localization';
import { IMAGE_WITH_TEXT, BLOCK_LABELS } from 'lib/block';
import { selectBlockContent } from 'lib/selectors';
import { THEMES } from 'lib/user-preferences';
import ContentActions from 'components/SideBarSettings/Content/ContentActions';
import ColorInput from 'components/ColorInput';
import {
  AlignmentSpacing,
  Content,
  Controls,
  useStyleSettings,
} from 'components/SideBarSettings/Shared';

import { imageWithTextDescriptionTemplate } from 'components/Editor/Templates/Content/image-with-text';

export function ImageWithTextSettings({
  blockContent,
  content,
  onChange,
  theme,
}) {
  const [showDescription, setShowDescription] = useState(true);
  const { id, style } = content ?? {};

  const handleChange = useCallback(
    patch => {
      onChange({
        blockId: id,
        contentChunk: { style: patch },
        useOriginalId: true,
      });
    },
    [id, onChange]
  );

  const handleDescriptionChange = show => {
    const [, textStack] = blockContent?.content?.items ?? [];
    const { id: textStackId, items: textStackItems } = textStack ?? {};
    const [titleBlock] = textStackItems;

    let description;
    if (show) {
      description = updateToLocalized(
        imageWithTextDescriptionTemplate(),
        getLocaleVariations(blockContent)
      );
    }

    const contentChunk = {
      items: [titleBlock, description].filter(Boolean),
    };

    onChange({
      blockId: textStackId,
      contentChunk,
      useOriginalId: true,
    });
    setShowDescription(show);
  };

  const [valueFor, handleChangeFor] = useStyleSettings({
    onChange: handleChange,
    style,
    theme,
  });

  const handleContentChange = updatedContent => {
    onChange({
      blockId: id,
      contentChunk: updatedContent,
      useOriginalId: true,
    });
  };

  return (
    <Content>
      <Controls>
        <FieldSet>
          <Label htmlFor="image-text-color-input">Background Color</Label>
          <ColorInput
            id="image-text-color-input"
            color={valueFor.backgroundColor}
            onChange={handleChangeFor.backgroundColor}
          />
        </FieldSet>
        <FieldSet>
          <Switch
            id="show-description"
            checked={showDescription}
            onCheckedChange={handleDescriptionChange}
            fullWidth
          >
            <Switch.Label htmlFor="show-description">
              Show Description
            </Switch.Label>
          </Switch>
        </FieldSet>
      </Controls>

      <Accordion.Root
        collapsible="true"
        defaultValue={['alignment-spacing']}
        type="multiple"
      >
        <AlignmentSpacing
          style={style}
          onChange={handleContentChange}
          spacingLabel={BLOCK_LABELS[IMAGE_WITH_TEXT]}
          hasPadding
          hasMargin
        />
        <ContentActions blockId={id} context={IMAGE_WITH_TEXT} />
      </Accordion.Root>
    </Content>
  );
}

ImageWithTextSettings.propTypes = {
  blockContent: BlockContentShape,
  content: BlockContentShape,
  onChange: PropTypes.func,
  theme: PropTypes.oneOf(THEMES),
};

const mapStateToProps = state => {
  const blockContent = selectBlockContent(state);

  return {
    blockContent,
    theme: selectUserPreferences(state).theme,
  };
};

export default connect(mapStateToProps)(ImageWithTextSettings);
