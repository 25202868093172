import styled from 'styled-components';
import { RadioButtonGroup } from 'ext/components/ui';

const BANNER_HEIGHT = 32;

export const StyledBar = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  height: calc(100% - var(--bottom-bar-height));
  width: var(--side-bar-width);
  background: var(--foreground-inverse);
  box-shadow: 0px -4px 16px rgb(0 0 0 / 25%);
  transition: transform 200ms ease-in-out;

  ${RadioButtonGroup} {
    height: 42px;
  }

  // Maintain the default sonar border color for inputs
  input {
    border-color: var(--border-input-default);
  }

  // Apply the sonar active state to buttons
  button[aria-pressed='true'] {
    color: var(--foreground-button-secondary-active);
    background: var(--background-button-secondary-active);
  }

  // Apply marging based on the number of banners shown to not overlap the sidebar
  > div:last-of-type {
    margin-bottom: ${({ bannersShown }) =>
      `${bannersShown * BANNER_HEIGHT}px;`};
  }
`;

export const Header = styled.header`
  z-index: 2;
  display: flex;
  align-items: center;
  height: 54px;
  gap: var(--spacing-small);
  padding: var(--spacing-small) var(--spacing-large);
  background: var(--foreground-inverse);
  box-shadow: var(--elevation-100);
`;
